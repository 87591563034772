.loading {
    margin: 0 auto;
    width: 100%;
    height: 70px;
}

.loader {
    position: absolute;
    top: auto;
    left: 50%;
    margin-left: -35px;
    width: 70px;
    height: 70px;
    box-sizing: border-box;
}

.loader .face {
    position: absolute;
    border: 2px solid transparent;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    border-left: 2px solid #46c5ff;
    border-bottom: 2px solid #46c5ff;
    border-top: 2px solid #46c5ff;
    animation: animate 1s linear infinite;
}

.circle {
    transform: rotate(-45deg);
}

.circle ::before {
    background-color: #46c5ff;
    box-shadow: 0 0 20px #46c5ff
}
@keyframes animate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}